import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import ExpozyScreen from "../../../assets/images/assets/screen_35.png";
import ExpozyScreen2 from "../../../assets/images/assets/screen_04.png";
import Faq from "../../../components/faq/Faq";
import AdminDashboardImage from "../../../assets/images/media/admin-dashboard.PNG";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import CheckMarkIcon from "../../../assets/images/icon/checkmark.svg";
import XIcon from "../../../assets/images/icon/x.svg";
import InfinityIcon from "../../../assets/images/icon/infinity.svg";
import B2CImage from "../../../assets/images/assets/b2c_commerce-1.png";
import MarketplaceImage from "../../../assets/images/assets/marketplace-1.png";
import BeyoundTheWeb from "../../../assets/images/assets/beyond_the_web-1.png";

const Solution = () => {
  useDocumentTitle(
    "Expozy | B2C eCommerce"
  );

  return (
    <div className="main-page-wrapper">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">The Expozy Solution</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
              Utilize cutting-edge innovative eCommerce functionality for supporting B2C, B2B, Marketplace, Multi-Store over single-one commerce API.
</p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* <!-- /.fancy-hero-one --> */}

      {/* =============================================
            Video Box Two
        ==============================================  */}
    <div className="hero-banner-five">

    <div
        className="img-gallery"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="400"
      >
        <div className="container text-center">
          <div className="screen-container">
            <img
              src={AdminDashboardImage}
              alt="Expozy Admin Panel"
              className="main-screen"
            />
            <img
              src="images/assets/screen_01.1.png"
              alt="shape"
              className="shapes screen-one"
            />
            <img
              src="images/assets/screen_01.2.png"
              alt="shape"
              className="shapes screen-two"
            />
          </div>
          {/* /.screen-container */}
        </div>
      </div>
      {/* /.img-gallery */}
    </div>

    <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Why us?</h6>
                  <h2>
                    <span>
                    B2C Commerce
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    
                  </h2>
                </div>
                <p className="sub-text">
                Fast and secure technology for global digital commerce: With our platform you can easily handle complex business models, using its highly scalable, modern cloud infrastructure.
                </p>
                <p className="sub-text pb-35">
                Our platform has a multitude of features to automate purchase processes and fortify the consumer-business relationship.
                The Expozy marketplace proivides additional functionality and tooling to lift your business off the ground in no time.
                </p>
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}

            <div
              className="col-lg-5 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={B2CImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
             
            </div>
            {/* End col-lg-5 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

          {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">

            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={MarketplaceImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              
            </div>
            {/* End col-lg-5 */}

            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Easy Product Management?</h6>
                  <h2>
                    <span>
                    Marketplace 
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                  </h2>
                </div>
                <p className="sub-text pb-35">
                Become a platform provider with our platform: Create your digital marketplace, integrate third-party vendors and offer millions of products through our scalable cloud platform


                </p>
            
              </div>
              
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

      {/* =============================================
            Fancy Feature Six
        ============================================== */}
      <div className="fancy-feature-six mt-140 md-mt-70">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
                {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">

          <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>All Order Data at a Glance</h6>
                  <h2>
                    <span>
                    Beyond the Web
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                  </h2>
                </div>
                <p className="sub-text pb-35">
                IoT, Chatbots, Social commerce, AR/VR, just to name a few are beyond the web solutions you can easily implement with our API-first platform.
                </p>
            
              </div>
             
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}


            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={BeyoundTheWeb} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
             
            </div>
            {/* End col-lg-5 */}

          </div>
        </div>

                                      {/* Forth pricing table */}
                                      <div className="container">
            <div className="title-style-two mb-85 md-mb-40 mt-85">
              <h3>
                <span>
                  How does Expozy compare to other platforms?
                  <img src="images/shape/line-shape-2.svg" alt="shape" />
                </span>
              </h3>
              
            </div>
            {/* End .title */}
            
            <div className="pricing-page-table">
     <table class="table">
        <thead>
          <tr>
            <th scope="col">Feature</th>
            <th scope="col">Expozy</th>
            <th scope="col">Shopify Plus</th>
            <th scope="col">Magento</th>
            <th scope="col">BigCommerce</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">API access for all store data</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
          </tr>
          <tr>
            <th scope="row">Custom data models</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>

          </tr>
          <tr>
            <th scope="row">Headless storefront themes</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
          </tr>
          <tr>
            <th scope="row">Unlimited product attributes and options</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
          </tr>
          <tr>
            <th scope="row">Subscription products</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>

          </tr>
          <tr>
            <th scope="row">Use vaulted credit cards with any gateway</th>
            <td><img src={CheckMarkIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
            <td><img src={XIcon} className="pricing-table-icon" /></td>
          </tr>
          
        </tbody>
      </table>
     </div>

          </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}
        </div>
      </div>
      {/* /.fancy-feature-six */}

  

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Ready to take your business to the next level?
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/pricing" className="theme-btn-eleven">
              {" "}
              Get Started
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

      {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};


export default Solution;
