import React from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import MobileAppPortfolio from "../../../components/portfolio/MobileAppPortfolio";
import MobileAppScreenSlider from "../../../components/portfolio/MobileAppScreenSlider";
import TestimonialThree from "../../../components/testimonial/TestimonialThree";
import BrandThree from "../../../components/brand/BrandThree";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import FancyFeatureTewentyThree from "../../../components/features/FancyFeatureTewentyThree";
import PricingSeven from "../../../components/pricing/PricingSeven";

const MobileAppLanding = () => {
  useDocumentTitle(
    " Expozy Mobile App"
  );
  return (
    <div className="main-page-wrapper font-gordita">
     <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div> 
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Turn your store into a mobile app!
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Use Expozy to turn your online store into a stunning mobile app. Contact us to request a quote.
              </p>
            </div>
          </div>
          {/* End .row */}

        </div>
        {/* End .container */}

        <div className="icon-box-one">
          <img src="images/logo/logo-39.png" alt="icon" />
        </div>
        <div className="icon-box-two">
          <img src="images/logo/logo-40.png" alt="icon" />
        </div>
        <div className="icon-box-three">
          <img src="images/logo/logo-41.png" alt="icon" />
        </div>
        <div className="icon-box-four">
          <img src="images/logo/logo-42.png" alt="icon" />
        </div>
        <div className="icon-box-five">
          <img src="images/logo/logo-43.png" alt="icon" />
        </div>
        <div className="icon-box-six">
          <img src="images/logo/logo-44.png" alt="icon" />
        </div>
        <div className="icon-box-seven">
          <img src="images/logo/logo-45.png" alt="icon" />
        </div>
        <div className="icon-box-eight">
          <img src="images/logo/logo-46.png" alt="icon" />
        </div>
      </div>
      {/* /.hero-banner-ten */}

      {/* =============================================
            App Screen Preview
        ==============================================  */}
      <div className="app-screen-preview-one">
        <div className="container">
          <div className="app-preview-slider-one">
            <MobileAppPortfolio />
          </div>
        </div>
        {/* End .container */}

        <img
          src="images/shape/bg5.svg"
          alt="shape"
          className="shapes round-bg"
        />
        <img
          src="images/shape/216.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/217.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/218.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/219.svg"
          alt="shape"
          className="shapes shape-four"
        />
      </div>
      {/* /.app-screen-preview-one  */}

     

      {/* 	=============================================
            Fancy Feature Twenty Five
        ==============================================  */}
      <div
        className="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
        id="features"
      >
        <div className="container">
          <div className="block-style-twentyFive">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img src="images/assets/screen_24.png" alt="screen" />
                </div>
                {/*  /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper pl-xl-5">
                  <h6>
                    Over <span>80% of all traffic is mobile.</span>
                  </h6>
                  <h3 className="title">Get more conversions</h3>
                  <p>
                    Visitors are more likely to make a purchase through their mobile
                    phone. Take advantage of the Expozy store to mobile app feature!
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}

          {/* /.block-style-twentyFive */}
        </div>
      </div>
      {/*  /.fancy-feature-twentyFive */}

     
     



      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve mt-50">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-ten text-center">
                <h2>Get in touch with us and get a great mobile app offer!</h2>
                <p className="pt-25 pb-45">
                  All your products and content will be imported.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center pb-40 md-pb-10 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150"><a className="theme-btn-eleven" href="/contact"> Get a Quote</a></div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/220.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/221.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-short-banner-twelve */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default MobileAppLanding;
