import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordRecovery from "../form/PasswordRecovery";
import { useModal } from 'react-hooks-use-modal';



const LoginForm = (props) => {
  // for password show hide
 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeName, setStoreName] = useState("");
  const redirectUrl = "https://admin.expozy.com/login_token.php";
    const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if(email.length <= 0) {
      toast.error("Please provide a valid email.");
    }

  else if(password.length <= 8) {
      toast.error("Password must be longer than 8 characters.")
    }

   

    else{

     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic KIkSG1SBGGtrnqqsks',
 
      },
      body: JSON.stringify({"email": email, "password": password })
  };
  fetch('https://core.expozy.com/api/login', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          
          toast.success('Authentication successful.');

          setTimeout(() =>{
            toast.success('You are now being logged in.');
          }, 1000);
          
            
          let form = document.getElementById("hidden-form");
          document.getElementById("token").value = response.token;
          document.getElementById("user_ip").value = props.ip;
          document.getElementById("project_name").value = response.project_name;


          setTimeout(function() { form.submit();}, 3000);
        

        }
        else {
          let error = response.errors[Object.keys(response.errors)[0]]
          toast.error(error);
        }
      })


    }
     
  };


  return (
    <>
      <form onSubmit={handleSubmit} className="user-data-form ">
        <div className="row">
          <div className="col-12">
            <div className="input-group-meta mb-80 sm-mb-70">
              <label>Email</label>
              <input
                placeholder="Enter Your Email"
                name="email"
                type="text"
                onChange={(event)=> {
                  setEmail(event.target.value) 
                }}
              />
             
            </div>
          </div>
          
          <div className="col-12">
            <div className="input-group-meta mb-25">
              <label>Password</label>
              <input
                placeholder="Enter Password"
                name="password"
                type="password"
                onChange={(event)=> {
                  setPassword(event.target.value) 
                }}
              />
          
           
            </div>
          </div>
         <div className="col-12">
            <p>Forgot your password? No worries, <a onClick={open} style={{cursor: "pointer", textDecoration: "underline"}}>recover it here.</a> </p>
         </div>
          <div className="col-12">
            <button className="theme-btn-one mt-50 mb-50">Login</button>
          </div>
        </div>
      </form>
      <form id="hidden-form" action={redirectUrl} method="post" style={{visibility: "hidden"}}>
         <input type="text" name="token" id="token" />
         <input type="text" name="ip" id="user_ip" />
         <input type="text" name="project_name" id="project_name" />

      </form>

       <ToastContainer 
      position="top-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
      <Modal>
          <div className="row">
          <div className="col-7 f-none-m-auto">
          
          
          <PasswordRecovery className="theme-footer-seven" close={close}/>
          </div>
          </div>
        {/* /.newsletter */}
      </Modal>
    </>
  );
};

export default LoginForm;
