import React from 'react';
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
<div className="trustpilot-widget" ref={ref} data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="61e7e790a67a7cb9cffaa9c7" data-style-height="24px" data-style-width="100%" data-theme="light">
  <a href="https://www.trustpilot.com/review/expozy.com" target="_blank" rel="noopener">Trustpilot</a>
</div>
    
  );
};
export default TrustBox;