import React, { useState, useMemo, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Recaptcha from 'react-google-invisible-recaptcha';
import { Link } from "react-router-dom";
import Select from 'react-select';
import countryList from 'react-select-country-list'; 

const PartnerForm = (props) => {
  // for validation


  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const refCaptcha = useRef(null)

  const subjectID = Math.random(1,99999);
 
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = country => {
    setCountry(country);
  }

  function removeSpecialChars(str) {
    return str.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
  }



  const handleForm = (e) => {
        // display form data on success
        e.preventDefault();
        console.log(fname);
        console.log(lname);
        console.log(email);
        console.log(message);
        e.target.reset();

        if(fname.length <= 0) {
          toast.error("Please input your first name.")
        }

        if(lname.length <= 0) {
          toast.error("Please input your last name.")
        }

        if(email.length <= 0) {
          toast.error("Please input your email.")
        }

        if(message.length <= 0) {
          toast.error("Please input your message.")
        }
        if(phone.length <= 0) {
          toast.error("Please input your phone number.")
        }
        if(companyName.length <= 0) {
          toast.error("Please input your company name.")
        }
        if(website.length <= 0) {
          toast.error("Please input your website.")
        }

        else {
          let formMessage = "phone: " + phone + "\n" + " website: " + website + "\n" + " company: " + companyName + "\n" + " country: " + country + "\n" + " message: " + message;
           // POST request using fetch inside useEffect React hook
     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic dVXQqZFufU1XG7VVOk',
 
      },
      body: JSON.stringify({ "fname": fname, "lname": lname, "email": email, "subject": "Partner Application" + subjectID, "message": formMessage  })
  };
  fetch('https://core.expozy.com/api/admin_contacts', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          toast.success("Thank you for applying! We'll get back to you shortly.");
          setTimeout(()=>{
            props.close();
          }, 2000);
        }
        else {
          toast.error('There was an error.');
        }
      }) 
        }

        // dovurshi validaciqta
  }

  return (
    <form className="user-data-form bg-white partner-form" onSubmit={handleForm}>
      <div className="messages"></div>
      <div className="row controls">
      <div className="col-md-11 mb-50"  >
            <h3>Partner application</h3> 

          </div>

          <div className="col-md-1">
          <a className="close-modal-button" onClick={props.close}>
            <i className="fa fa-times-circle-o" style={{fontSize: "2rem", color:"#000"}}>
            </i>
            </a>
          </div>
         
        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              onChange={(event) => {
                setFName(removeSpecialChars(event.target.value))
              }}
            />
            
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              onChange={(event) => {
                setLName(removeSpecialChars(event.target.value))
              }}
            />
     
          </div>
        </div>
        {/* End .col */}

        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              onChange={(event)=>{
                setEmail(event.target.value)
              }}
            />
            
          </div>
        </div>

         <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Phone</label>
            <input
              placeholder="Enter Your Phone Number"
              name="phone"
              type="tel"
              onChange={(event)=>{
                setPhone(event.target.value)
              }}
            />
            
          </div>
        </div>
        {/* End .col */}

        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Company Name</label>
            <input
              placeholder="Enter Your Company Name"
              name="company"
              type="text"
              onChange={(event)=>{
                setCompanyName(removeSpecialChars(event.target.value))
              }}
            />
            
          </div>
        </div>

         <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Website</label>
            <input
              placeholder="Enter Your Website"
              name="website"
              type="text"
              onChange={(event)=>{
                setWebsite(event.target.value)
              }}
            />
            
          </div>
        </div>

        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Country</label>
            <Select options={options} value={country} onChange={changeHandler} />
          </div>
        </div>


        <div className="col-6">
          <div className="input-group-meta form-group lg mb-40">
            <label>Message</label>
            <textarea
              placeholder="Your message goes here.."
              name="sendMessage"
              type="text"
              onChange={(event)=>{
                setMessage(event.target.value)
              }}
            ></textarea>
            
          </div>
        </div>
        {/* End .col */}

          <div className="col-12">
          <div className="input-group-meta form-group lg mb-40">
            <p>By submitting the form you agree to Expozy's <Link to="/terms-and-conditions" style={{textDecoration: "underline"}}>Terms & Conditions</Link> and <Link to="/privacy-policy" style={{textDecoration: "underline"}}>Privacy Policy.</Link></p> 
            
          </div>
        </div>

        <div className="col-12">
          <button type="submit" className="theme-btn-two">
            Apply
          </button>
        </div>
        {/* End .col */}
      </div>
      <ToastContainer 
      position="bottom-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
<Recaptcha
  ref={refCaptcha}
  sitekey="6LeAmZwbAAAAAOYM5lhUC-Dvat2wVw5L4arlJERk"
  onResolved={ () => console.log( 'Human detected.' ) } />
    </form>
  );
};

export default PartnerForm;
