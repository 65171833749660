import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {WorkIcon as ReactComponent} from "../../../assets/images/media/logo-expo-red.png";
import SchoolIcon from "../../../assets/images/media/logo-expo-red.png";
import StarIcon from "../../../assets/images/media/logo-expo-red.png";

const Roadmap = () => {
  useDocumentTitle(
    " Expozy Roadmap"
  );

  return (
    <div className="main-page-wrapper">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">Expozy has big plans in store.</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
        Learn more about Expozy's future in the roadmap section below.
    </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* <!-- /.fancy-hero-one --> */}

      {/* =============================================
            Video Box Two
        ==============================================  */}
 


      {/* <!-- /.fancy-text-block-twelve --> */}

          {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
     
     <div className="fancy-feature-six mt-140 md-mt-70">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
          
          <div className="row">
          <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#E52711', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #E52711' }}
    date="2022 - Q2"
    iconStyle={{ background: '#E52711', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Stage 1</h3>
    <h4 className="vertical-timeline-element-subtitle">Expozy SaaS</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" style={{marginTop: "1em"}}/>
    <p>
      Token Launch on BSC, 250 Telegram Members
    </p>
    </div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2022 - Q3"
    iconStyle={{ background: '#E52711', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Stage 2</h3>
    <h4 className="vertical-timeline-element-subtitle">Internal Swap</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" readOnly style={{marginTop: "1em"}}/>
    <p>
      ICO round 1 launch, Social media campaigns, 500 Telegram members
    </p>
    </div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2022 - Q4"
    iconStyle={{ background: '#E52711', color: '#fff' }}
  
  >
    <h3 className="vertical-timeline-element-title">Stage 3</h3>
    <h4 className="vertical-timeline-element-subtitle">ICO Round 2 launch</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" style={{marginTop: "1em"}}/>
    <p>
      Team showcase, press conference, 750 Telegram members
    </p>
    </div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2023 - Q1"
    iconStyle={{ background: '#E52711', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Stage 4</h3>
    <h4 className="vertical-timeline-element-subtitle">NFT Marketplace SaaS</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" style={{marginTop: "1em"}}/>
    <p>
      NFT Physical products protocol, 1500 Telegram members, 750 EXY Holders
    </p>
    </div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2023 - Q2"
    iconStyle={{ background: '#E52711', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Stage 5</h3>
    <h4 className="vertical-timeline-element-subtitle">NFT Rent Protocol</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" style={{marginTop: "1em"}}/>
    <p>
      NFT rent terms protocol, 6500 Telegram members, 2250 EXY holders
    </p>
    </div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2023 - Q3"
    iconStyle={{ background: '#E52711', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Stage 6</h3>
    <h4 className="vertical-timeline-element-subtitle">NFT Metaverse Bridge</h4>
    <div className="d-flex align-items-baseline" style={{gap: "5px"}}>
    <input type="checkbox" style={{marginTop: "1em"}}/>
    <p>
      11050 Telegram members, 4250 EXY Holders
    </p>
    </div>
  </VerticalTimelineElement>
  
</VerticalTimeline>
          </div>

        </div>
      </div>

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
     

      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">

          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  You've got some questions? See our FAQ.
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/faq" className="theme-btn-eleven">
              {" "}
              FAQ
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

     {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};


export default Roadmap;
