import React from "react";

const PricingContent = [
  {
    pcakName: "Basic",
    bgColor: "#ffecec",
    price: "Free",
    buttonText: "Free trial",
    packDetails: "A Great Start",
    features: [
      "Pages",
      "Blog",
      "Product Inventory",
      "User Registration",
      "Comments",
      "Menu design",
      "Ads List",
      "Ad Types",
      "Email Templates",
    ],
    animationDelay: "",
    activeItem: "",
  },
  {
    pcakName: "Intermediate",
    bgColor: "#E3F8EF",
    price: "8.99",
    buttonText: "Buy now",
    packDetails: "Small Business",
    features: [
      "Pages",
      "Blog",
      "Product Inventory",
      "Order Summary",
      "Pos Module",
      "User Registration",
      "User Subscription",
      "Questions & Answers",
      "Comments",
      "Menu design",
      "Ads List",
      "Ad Types",
      "Ad Statuses",
      "Ad Categories",
      "Email Templates",
      "Newsletter"
    ],
    animationDelay: "100",
    activeItem: "active",
  },
  {
    pcakName: "Enterprise",
    bgColor: "#fbf3e5",
    price: "17.99",
    buttonText: "Buy now",
    packDetails: "Established Business",
    features: [
      "Pages",
      "Blog",
      "Product Inventory",
      "Order Summary",
      "Pos Module",
      "Warehouse",
      "User Registration",
      "User Subscription",
      "Questions & Answers",
      "Comments",
      "Messaging",
      "User Wallets",
      "HR Module",
      "Menu Design",
      "Slider Design",
      "Banner Design",
      "Ads List",
      "Ad Types",
      "Ad Statuses",
      "Ad Categories",
      "Ad Subscriptions",
      "Ad Packages",
      "Email Templates",
      "Newsletter"
    ],
    animationDelay: "200",
    activeItem: "",
  },
];


const PricingYeary = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeItem}`}>
            <div className="pack-name" style={{ background: val.bgColor }}>
              {val.pcakName}
            </div>
            <div className="price">{val.price}</div>
            <div className="pack-details">{val.packDetails}</div>
            <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <a href="#" className="trial-button">
             {val.buttonText}
            </a>
            <div className="trial-text">No card required, cancel any time</div>
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingYeary;
