import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Is becoming a partner free?",
    desc: `There are no fees or costs to become a partner.`,
    expand: "a",
  },
  {
    title: "Are there any sales targets?",
    desc: `There are three partner levels: Bronze, Silver, and Gold. Each partner level has its benefits and requirements. There are no minimal sales targets for the Bronze partner level (however, sales targets are set for Silver and Gold partners).`,
    expand: "b",
  },
  {
    title: "How can I be sure Expozy is the right solution for my clients?",
    desc: `Expozy carries a wide toolkit, beneficial for different types of businesses. The Expozy toolking makes day-to-day problems child's play.`,
    expand: "d",
  },
  {
    title: "How do I get my partner commission?",
    desc: `Expozy's partners can buy license keys and commercial subscriptions at a discounted rate, depending on their partner level (20%, 40% or 50%). Afterwards those keys or subscriptions can be sold to your customers for their full price.`,
    expand: "e",
  }
  
];

const PartnersFaq = () => {
  return (
    <div className="accordion-style-two pr-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default PartnersFaq;
