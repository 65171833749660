import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import ExpozyScreen from "../../../assets/images/assets/screen_35.png";
import ExpozyScreen2 from "../../../assets/images/assets/screen_04.png";
import Faq from "../../../components/faq/Faq";
import AdminDashboardImage from "../../../assets/images/media/admin-dashboard.PNG";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import OmniChannelImage from "../../../assets/images/content/omnichannel.png";
import InventoryManagementImage from "../../../assets/images/content/discounts_promotions.png";
import OrderManagementImage from "../../../assets/images/content/create_manage_orders.png";
import OminChannelSection from "../../../components/omnichannelComponent/OmnichannelComponent";



const ShopModule = () => {
  useDocumentTitle(
    "Expozy | Omnichannel eCommerce"
  );

  return (
    <div className="main-page-wrapper">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">The Expozy Shop Module</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
              Safely manage your most critical data and processes with a secure and intuitive user interface.
</p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* <!-- /.fancy-hero-one --> */}

      {/* =============================================
            Video Box Two
        ==============================================  */}
    <div className="hero-banner-five">

    <div
        className="img-gallery"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="400"
      >
        <div className="container text-center">
          <div className="screen-container">
            <img
              src={AdminDashboardImage}
              alt="Expozy Admin Panel"
              className="main-screen"
            />
            <img
              src="images/assets/screen_01.1.png"
              alt="shape"
              className="shapes screen-one"
            />
            <img
              src="images/assets/screen_01.2.png"
              alt="shape"
              className="shapes screen-two"
            />
          </div>
          {/* /.screen-container */}
        </div>
      </div>
      {/* /.img-gallery */}
    </div>

    

    <div className="fancy-text-block-twelve mt-170 md-mt-100">
    <OminChannelSection/>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Limitless eCommerce</h6>
                  <h2>
                    <span>
                    Omnichannel {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    eCommerce capability
                  </h2>
                </div>
                <p className="sub-text pb-35">
                An omnichannel e-commerce strategy foresees that customers may start searching on one channel and move to another as a progression of their online purchasing journey. Omnichannel completes a multichannel strategy and optimizes it for today’s online customers.                </p>
                <p className="sub-text pb-35">
                One of the main benefits of having omnichannel e-commerce is that you provide your customers with cohesive messaging about your brand. No matter where a customer encounters your brand, the messaging is always the same.
                </p>
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}

            <div
              className="col-lg-5 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={OmniChannelImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              <div className="text-wrapper">
               
               <p className="sub-text pb-35" >
               Omnichannel e-commerce allows your company to collect and merge customer data from multiple channels. Combined, this data gives you a full picture of what types of customers are interested in your brand and their behavior, which gives you the power to personalize your customer journey.


               </p>
              </div>
            </div>
            {/* End col-lg-5 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

          {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">

            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={InventoryManagementImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              <div className="text-wrapper">
                <p className="sub-text">In addition, our solution will allow you to deliver high-conversion commerce experiences faster than your competitors.</p>
            </div>
            </div>
            {/* End col-lg-5 */}

            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Easy Product Management?</h6>
                  <h2>
                    <span>
                    Create and manage {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    discounts and promotions for products
                  </h2>
                </div>
                <p className="sub-text pb-35">
                Expozy provides your team with an easy way to build complex discounts for your products. 
                </p>
            
              </div>
              <ul className="list-item-one">
                    <li>
                      Track all active or inactive discounts by date or by total amount

                    </li>
                    <li>
                    Create targeted discounts for specific products
                    </li>
                    <li>
                    Limit the discount to apply to specific channels, countries and customer groups
                    </li>
                   
                 </ul>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

      {/* =============================================
            Fancy Feature Six
        ============================================== */}
      <div className="fancy-feature-six mt-140 md-mt-70">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
                {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">

          <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Orders</h6>
                  <h2>
                    <span>
                    Manage orders {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    effortlessly
                  </h2>
                </div>
                <p className="sub-text pb-35">
                The Orders Module enables direct access to all orders without the need of an external application – whether it comes from a webstore, a mobile app, POS, or an IoT device. 
                </p>
            
              </div>
              
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}


            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={OrderManagementImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
             
            </div>
            {/* End col-lg-5 */}

          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}
        </div>
      </div>
      {/* /.fancy-feature-six */}

  

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Ready to take your business to the next level?
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/pricing" className="theme-btn-eleven">
              {" "}
              Get Started
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

       {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};


export default ShopModule;
