import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import Team1 from "../../../src/assets/images/media/team1.jpg";
import Team2 from "../../../src/assets/images/media/team2.jpg";
import Team3 from "../../../src/assets/images/media/team3.jpg";
import Team4 from "../../../src/assets/images/media/team4.jpg";
import Team5 from "../../../src/assets/images/media/gabi-avatar.jpg";
import Team6 from "../../../src/assets/images/media/jorkata-avatar.jpg";
import Team7 from "../../../src/assets/images/media/qncho-avatar.jpg";


export default function TeamSlider() {
  const TeamContent = [
    {
      designation: "Founder",
      name: "Evgeni N.",
      img: Team4,
      animationDelay: "50",
    },
    {
      designation: "Front End Lead",
      name: "Rosen D.",
      img: Team2,
      animationDelay: "100",
    },
    {
      designation: "Software Lead Engineer",
      name: "Alexander P.",
      img: Team1,
      animationDelay: "150",
    },
    {
      designation: "Marketing Expert",
      name: "Stan J.",
      img: Team3,
      animationDelay: "200",
    },
    {
      designation: "Marketing Expert",
      name: "Gabi G.",
      img: Team5,
      animationDelay: "200",
    },
    {
      designation: "Senior Backend Developer",
      name: "George K.",
      img: Team6,
      animationDelay: "200",
    },
    {
      designation: "Full Stack Developer",
      name: "Yancho  Y.",
      img: Team7,
      animationDelay: "200",
    }
  ];

  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {TeamContent.map((item, i) => (
          <div
            className="item"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={item.animationDelay}
            key={i}
          >
            <Link to="#" className="team-member team-block-one">
              <div className="info">
                <div className="desig">{item.designation}</div>
                <div className="name">{item.name}</div>
              </div>
              <div className="img-meta">
                <img src={`${item.img}`} alt="team" />
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
}
