import React, { useState, useEffect } from "react";
import useDocumentTitle from "../../../../components/useDocumentTitle";
import { Link } from "react-router-dom";
import LoginForm from "../../../../components/contact/form/LoginForm";
import ExpozyLogo from "../../../../assets/images/media/logo-expo-red.png";
import axios from 'axios';



const Login = () => {


  useDocumentTitle("Login || Expozy eCommerce");

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIP(res.data.IPv4)
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    console.log('use effect');
    getData()

  }, [])


  return (
    <div className="main-page-wrapper p0">
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <h3 className="font-rubik">
            Login to view your subscription <br />
            plan or manage your project.
          </h3>
          <div className="illustration-holder">
            <img
              src="images/assets/ils_08.svg"
              alt="illustration"
              className="illustration"
            />
            <img
              src="images/assets/ils_08.1.svg"
              alt="illustration"
              className="shapes shape-one"
            />
            <img
              src="images/assets/ils_08.2.svg"
              alt="illustration"
              className="shapes shape-two"
            />
          </div>
        </div>
        {/* /.illustration-wrapper */}

        <div className="form-wrapper">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/">
                <img src={ExpozyLogo} alt="logo" className="expozy-logo" />
              </Link>
            </div>
            <Link
              className="font-rubik go-back-button"
              to="/"
            >
              Home
            </Link>
          </div>
          <div className="mt-80 md-mt-40">
            <h2>
              Hi, welcome <br /> Back!
            </h2>
            <p className="header-info pt-30 pb-50">
              Still don't have an account? <Link to="/pricing">Sign Up</Link>
            </p>
          </div>

          <LoginForm ip={ip}/>
          {/* Login Form End */}
          <p className="text-center font-rubik copyright-text">
            Copyright @{new Date().getFullYear()}{" "}
            <a
              href="https://expozy.com"
              target="_blank"
              title="myFrame"
              rel="noreferrer"
            >
              Expozy 
            </a>{" "}
            Inc.
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* /.user-data-page */}
     
    </div>
  );
};

export default Login;
