import React from "react";
import { Link } from "react-router-dom";
import Collapsible from 'react-collapsible';
import Studioweb from "../../assets/images/icon/studioweb-bg.png";
import a1 from "../../assets/images/icon/a1.png";


const FeaturesContent = [
  {
    img: Studioweb,
    title: "Studioweb.bg",
    desc: `Studioweb is a company with years of experience in the Web dev field. They're one of Expozy's first partners.`,
    routePath: "/",
    dataDelay: "100",
    partnerLevel: "Gold",
    email: "",
    phone: "",
    website: "https://studioweb.bg",
    services: "Expozy Business Development, Third Party Integrations",
    Experience: `5+`,
      social: [
        {
          icon: "fa fa-facebook",
          link: "https://www.facebook.com/studioweb.bg/",
        },
        {
          icon: "fa fa-instagram",
          link: "https://www.instagram.com/studioweb.bg/",
        },
        {
          icon: "fa fa-twitter",
          link: "https://twitter.com/BgStudioweb",
        },
        {
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/studioweb-bg-6a531253/",
        },
        {
          icon: "fa fa-youtube",
          link: "https://www.youtube.com/channel/UCeFxq1oHKwnH6RKz3F_-NDg",
        },
      ],
  },
  {
    img: a1,
    title: "A1",
    desc: `A1 is one of the biggest telecom companies worldwide. They've been early adopters of Expozy and are now offering a full range of services.`,
    routePath: "/",
    dataDelay: "200",
    email: "",
    phone: "",
    partnerLevel: "Bronze",
    website: "https://www.a1.digital/en-de/",
    services: "Expozy Business Development, Third Party Integrations",
    Experience: ` 3+`,
    social: [
      {
        icon: "fa fa-facebook",
        link: "https://m.facebook.com/A1Bulgaria/",
      }
    ],
  },
];

const FancyFeatureEight = () => {
  return (
    <div className="row" style={{background: "#fff", borderRadius: "10px", padding: "0 1rem"}}>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-ten partner-card-content">
          {/*   <div className="icon">
              <img src={val.img} alt="icon" />
            </div> */}
            <div className="title-style-three mb-20">
            <h2>
              <span>{val.title} 
                <img src="images/shape/line-shape-6.svg" className="partner-heading-underline" alt="" />
              </span>
              </h2>
              </div>
              <div className="text-wrapper">
              <h4 className="font-gilroy-bold">Partner Level</h4>
              <p>{val.partnerLevel}</p>
              <h4 className="font-gilroy-bold">Contact Info</h4>
                  <ul className="social-icon d-flex justify-content-center mb-20">
                    {val.social.map((social, i) => (
                      <li key={i}>
                        <a href={social.link} target="_blank" rel="noreferrer">
                          <i className={social.icon}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
              </div>
            <a className="theme-btn-eleven" href={val.website} target="_blank">Visit Partner</a>
                
            
          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureEight;
