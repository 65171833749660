import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const FormFooterSignup = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(email);

     // POST request using fetch inside useEffect React hook
     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic dVXQqZFufU1XG7VVOk',
 
      },
      body: JSON.stringify({ "email": email, "newsletter-checkbox": "1" })
  };
  fetch('https://core.expozy.com/api/subscribe', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          toast.success('Subscription successful.');
        }
        else {
          toast.error(response.response);
        }
      })  
     
  };

 
  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Enter your email" onChange={(event)=> {
        setEmail(event.target.value) 
      }
      } />
      <button type="submit">Sign UP</button>
      <ToastContainer 
      position="top-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
    </form>
  );
};

export default FormFooterSignup;
