import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/Expozy-108019807443777/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/in/expozy-limited-623baa199/",
  },{
    icon: "fa-telegram",
    link: "https://t.me/expozy",
  }
];

const ContactAddressTwo = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/15.svg" alt="icon" />
          </div>
          <div className="title">Location</div>
          <p className="font-rubik">
            Hong Kong <br /> Kaiser Centre, 18 Centre Street, Sai Ying Pun
          </p>
        </div>
        {/* /.address-info */}
      </div>
      {/* End .col */}

      <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/16.svg" alt="icon" />
          </div>
          <div className="title">Contact</div>
          <p className="font-rubik">
            hello@expozy.com <br />
            <Link to="/partners-directory" style={{textDecoration: "underline"}}>See Partners </Link>
          </p>
        </div>{" "}
        {/* /.address-info */}
      </div>
      {/* End .col */}

      <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/17.svg" alt="icon" />
          </div>
          <div className="title">Social Media</div>
          <p className="font-rubik">Follow on social media</p>
          <ul className="d-flex justify-content-center">
            {socialContent.map((val, i) => (
              <li key={i}>
                <a href={val.link} target="_blank" rel="noreferrer">
                  <i className={`fa ${val.icon}`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>{" "}
        {/* /.address-info */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default ContactAddressTwo;
