import React from "react";

const FeaturesContent = [
  {
    img: "94",
    title: "Cloud Native",
    desc: `Using state-of-the-art technology such as ReactJS, NodeJS and NextJS, reaching 100/100 Google Lighthouse scores.`,
  },
  {
    img: "95",
    title: "Headless",
    desc: `Our headless commerce platform is responsible for all backend processes, calculation and data storage.`,
  },
  {
    img: "96",
    title: "API-first",
    desc: `APIs guarantee a fast and safe exchange of information, so businesses can rely on them to connect different applications.`,
  },
  {
    img: "97",
    title: "Third Party Integrations",
    desc: ` Expozy offers a vast array of integration possibilities - from its own marketplace or any external one.`,
  },
  {
    img: "98",
    title: "Microservices Architecture",
    desc: `Microservices are small self-contained applications, which allow companies to experiement, test, design and rapidly implement new services.`,
  },
  {
    img: "99",
    title: "Outstanding Customer Support",
    desc: `  The Expozy team is at your service 24/7.`,
  },
];
const FancyFeatureSeventeen = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${val.img}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default FancyFeatureSeventeen;
