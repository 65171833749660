import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
const QuickRegister = (props) => {
  // for password show hide

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeName, setStoreName] = useState("");
  const packageID = props.packageID;
  const packageName = props.packageName;
 

  // used to login
  const [projectName, setProjectName] = useState("");
  const [token, setToken] = useState("");
  const redirectUrl = "https://admin.expozy.com/login_token.php";


  function removeSpecialChars(str) {
    return str.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    if(email.length <= 0) {
      toast.error("Please provide a valid email.");
    }

    else if(password.length <= 8) {
      toast.error("Password must be longer than 8 characters.")
    }

    else if(storeName.length <= 0) {
      toast.error("Please provide a store name.");
    }

    else{

     

     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic KIkSG1SBGGtrnqqsks',
 
      },
      body: JSON.stringify({ "title": storeName, "email": email, "password": password, "planId": packageID })
  };
  fetch('https://core.expozy.com/api/saas', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
        //  setToken(response.token);
          setProjectName(response.title);
          
          toast.success('Registration successful.');

          setTimeout(() =>{
            toast.success('You are now being logged in.');
          }, 1000);
          
            
          let form = document.getElementById("hidden-form");
          document.getElementById("project_name").value = response.title;
          document.getElementById("user_token").value = response.token;
          document.getElementById("user_ip").value = props.ip;

          setTimeout(function() { form.submit();}, 2000);
        
          setTimeout(()=>{
            props.close();
          }, 3000);

        }
        else {
          let error = response.errors[Object.keys(response.errors)[0]]
          toast.error(error);
        }
      })


    }
     
  };



  return (
    <>
      <form className="user-data-form bg-white" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-11 mb-50"  >
            <h3>Register to set up your store</h3> 

          </div>

          <div className="col-md-1">
          <a className="close-modal-button" onClick={props.close}>
            <i className="fa fa-times-circle-o" style={{fontSize: "2rem", color:"#000"}}>
            </i>
            </a>
          </div>
         
          <div className="col-6">
            <div className="input-group-meta mb-50">
              <label>Email</label>
              <input
                placeholder="Enter Your Email"
                name="email"
                type="email"
                onChange={(event)=> {
                  setEmail(event.target.value) 
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group-meta mb-50">
              <label>Password</label>
              <input
                placeholder="Enter Password"
                name="password"
                type="password"
                onChange={(event)=> {
                  setPassword(removeSpecialChars(event.target.value)) 
                }}
              />

            </div>
          </div>
          <div className="col-6">
            <div className="input-group-meta mb-50">
              <label>Store Name</label>
              <input
                placeholder="Enter a name for your store"
                name="store-name"
                type="text"
                onChange={(event)=> {
                  setStoreName(removeSpecialChars(event.target.value)) 
                }}
              />

            </div>
          </div>
          <div className="col-6">
            <div className="input-group-meta mb-50">
              <label>Package</label>
              <input
                placeholder={props.packageName}
                name="Package"
                type="Text"
                value={props.packageName}
                readOnly
              />

            </div>
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="theme-btn-thirteen mt-30 mb-50 f-none-m-auto">
              Create your store
            </button>
          </div>

          
          {/* End .col */}
        </div>
       
      </form>
    <form id="hidden-form" action={redirectUrl} method="post" style={{visibility: "hidden"}}>
         <input type="text" name="project_name" id="project_name" />
         <input type="text" name="token" id="user_token" />
         <input type="text" name="ip" id="user_ip" />
         <button type="submit" >test</button>
      </form>

       <ToastContainer 
      position="top-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
      
    </>
  );
};

export default QuickRegister;
