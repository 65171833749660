import React from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import ContactAddressTwo from "../../../components/contact/address/ContactAddressTwo";
import ContactForm from "../../../components/contact/form/ContactForm";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import { Link } from "react-router-dom";

const ExpozyContact = () => {
    useDocumentTitle(
        "Contact Expozy Support"
    );
    return (
        <div className="main-page-wrapper">
            <div className="inner-page-white-nav-items">
                <HeaderLandingWebsiteBuilder />
            </div>       {/* End Header */}

            {/* =============================================
				Fancy Hero One
			==============================================  */}
            <div className="fancy-hero-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-11 m-auto">
                            <div className="page-title">Contact our support team information and assistance on any topic.</div>
                            <h2 className="font-rubik">
                                Contact Expozy Support
              </h2>
                        </div>
                    </div>
                    {/* End .row */}
                </div>
                {/* End .container */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
                <div className="bubble-six"></div>
            </div>
            {/* /.fancy-hero-one */}

            {/* 	=============================================
				Contact Us Light
			==============================================  */}
            <div className="contact-us-light pt-140 pb-200 md-pt-90 md-pb-80">
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="container">
                    <div className="row pricing-table-area-six">
                        <div className="col-4 col-xs-12 pr-table-wrapper active fancy-hero-five">
                <a href="https://blog.expozy.com" target="_blank">        
                <h3 className="heading" style={{fontSize: "45px"}} >
                <span>Blog</span>
                </h3>
                </a>
                        </div>

                        <div className="col-4 col-xs-12 pr-table-wrapper active fancy-hero-five">
                        <a href="https://wiki.expozy.com/" traget="_blank">
                        <h3 className="heading" style={{fontSize: "45px"}}>
                        <span>Wiki </span>
                        </h3>
                        </a>
            </div>

                        <div className="col-4 col-xs-12 pr-table-wrapper active fancy-hero-five">
                        <Link to="/faq">
                        <h3 className="heading" style={{fontSize: "45px"}}><span>Faq </span></h3>
                        </Link>
            </div>
                    </div>

                    <div className="form-style-light">
                        <ContactForm />
                    </div>
                </div>
            </div>
            {/* /.contact-us-light */}

            {/* =====================================================
        Footer Style Seven
        ===================================================== */}
            <footer className="theme-footer-seven mt-120 md-mt-100">
                <div className="lg-container">
                    <div className="container inner-btn-black">
                        <FooterSeven />
                    </div>

                    <div className="container">
                        <div className="bottom-footer">
                            <CopyRightThree />
                        </div>
                    </div>
                </div>
                {/* /.lg-container */}
            </footer>
            {/* /.theme-footer-seven */}
        </div>
    );
};

export default ExpozyContact;
