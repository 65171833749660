import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../components/footer/FooterSeven";
import CopyRightThree from "../components/footer/CopyRightThree";


const NotFound = () => {
  useDocumentTitle("Not Found || Expozy");
  return (
    <div className="main-page-wrapper">
    <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>    
      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/media/404.svg"
            alt="media"
            className="w-100 illustration"
          />
          <img
            src="images/media/404-q.svg"
            alt="media"
            className="shapes qus"
          />
        </div>
        <div className="text-wrapper order-lg-first">
         
          <h1 className="font-slab">
            Sorry, <br />
            The Page Can’t <br />
            be Found.
          </h1>
          <p className="font-rubik">
            Maybe the page you are looking for no longer exists.
          </p>

          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Back to Home</span>
            <img src="images/icon/53.svg" alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
        {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default NotFound;
