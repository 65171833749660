import React from "react";
import { Link } from "react-router-dom";

import Shape3 from "../../../src/assets/images/shape/232.svg";
import Shape4 from "../../../src/assets/images/shape/233.svg";
import Shape5 from "../../../src/assets/images/shape/234.svg";
import Shape6 from "../../../src/assets/images/shape/235.svg";
import Shape7 from "../../../src/assets/images/shape/236.svg";
import Screen32 from "../../../src/assets/images/assets/screen_32.png";
import Screen33 from "../../../src/assets/images/assets/screen_33.png";
import Screen34 from "../../../src/assets/images/assets/screen_34.png";
import HeroImage from "../../../src/assets/images/content/hero_image2.png";


const HeroBannerTwelve = () => {
  return (
    <div className="hero-banner-twelve lg-container" id="home">
      <div className="container">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-md-10 m-auto text-center">
            <h1 className="hero-heading font-gordita" data-aos="fade-up">
            Headless e-commerce {" "}
              <span style={{ color: "#FF006B" }}> higher</span>{" "}
              <span style={{ color: "#FEDC00" }}>conversion</span> rates
            </h1>
            <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
            > 
            Expozy employs Cloud-native, API, Microservices aproaches to provide a imaculate storefronts and an intuitive dashboard.
            Expozy is ready to help you grow.
            
            </p>
            <Link
              to="/pricing"
              className="get-start-btn"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Get Started fo free{" "}
              <i className="fa fa-angle-right ml-1" aria-hidden="true"></i>
            </Link>
          </div>
        </div>

        <div className="screen-holder" data-aos="fade-up">
          <img
            src={HeroImage}
            alt="screen"
            className="img-meta"
          />
          <img
            src={Screen33}
            alt="screen"
            className="shapes screen-one"
          />
          <img
            src={Screen34}
            alt="screen"
            className="shapes screen-two"
          />
          <img
            src={Shape3}
            alt="screen"
            className="shapes shape-one"
          />
          <img
            src={Shape4}
            alt="screen"
            className="shapes shape-two"
          />
          {/* </div> <!-- /.screen-holder --> */}
        </div>
        <img
          src={Shape5}
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src={Shape6}
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src={Shape3}
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src={Shape4}
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src={Shape5}
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src={Shape7}
          alt="shape"
          className="shapes shape-eight"
        />
      </div>
    </div>
    // /.hero-banner-twelve
  );
};

export default HeroBannerTwelve;
