import React from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import { Link } from "react-router-dom";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import FeatureFour from "../../../components/features/FeatureFour";
import PartnerForm from "../../../components/contact/form/PartnerForm";
import FancyFeatureEight from "../../../components/features/FancyFeatureEight";


const ExpozyPartners = () => {
  useDocumentTitle("Expozy | Headless, API-driven, microservices eCommerce platform");

  return (
    <div className="main-page-wrapper font-gordita">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* 	=============================================
            Fancy Hero One
        ============================================== */}
       <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">Expozy<br/> Partners</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
                Browse through the Expozy certified partner list. All partners are manually vetted and are
                guaranteed to provide the highest quality of service.
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

        <div className="fancy-text-block-one pt-130 pb-120 md-pt-100 md-pb-80">
        <div className="container">
          <div className="wrapper">
            <div className="row justify-content-center">
              <FancyFeatureEight />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}



      {/* =============================================
            Fancy Feature Six
        ============================================== */}
     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Ready to partner up? Send us your application!
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/become-a-partner" href="#" className="theme-btn-eleven">
              {" "}
              Apply for partnership
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

       {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
     
    </div>
  );
};

export default ExpozyPartners;
