import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import CallToActionTwo from "../../../components/call-to-action/CallToActionTwo";
import CounterFiveColored from "../../../components/counter/CounterFiveColored";
import TeamSlider from "../../../components/team/TeamSlider";
import SocialTwo from "../../../components/social/SocialTwo";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import FeaturesAboutPage from "../../../components/features/FeatuesAboutPage";
import FooterSeven from "../../../components/footer/FooterSeven";
import AboutImage from "../../../../src/assets/images/media/ils_20.png";
import Expozyverse from "../../../../src/assets/images/assets/ils_03.svg";


import ExpozyAboutImageTwo from "../../../../src/assets/images/media/quote-image.png";


const ExpozyAbout = () => {
  useDocumentTitle(
    " About Expozy"
  );
  return (
    <div className="main-page-wrapper p0">
<div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}
      {/* End Header */}

      {/* =============================================
				Fancy Hero Five
			============================================== */}
      <div className="fancy-hero-five">
        <img
          src="images/shape/93.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/94.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-8 col-sm-10">
                <h1 className="heading">
                  <span>
                 Expozy is a simple solution {" "}
                    <img src="images/shape/line-shape-11.svg" alt="shape" />
                  </span>
                  to a complex problem
                </h1>
                <p className="sub-heading">
                We believe that brands should never be obscured by technology and that eCommerce should be simple, lightweight, and fun. As developers at heart, we believe that eCommerce platforms, while optimized for traditional channels, hinder a brand’s creative freedom because of rigid templates, cluttered interfaces, and needless plugins.
                </p>
                
              </div>
              <div className="col-lg-5 col-md-4">

                <img
                  src={AboutImage}
                  alt="media"
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-five */}

      {/* =============================================
				Fancy Text block Seventeen
			============================================== */}
      <div className="fancy-text-block-seventeen mt-50 pt-20 md-mt-80">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-5 ml-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <img src={ExpozyAboutImageTwo} />
            </div>
            {/* End .col */}
            <div
              className="col-xl-6 col-lg-7 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
            <div className="title-style-four mb-40">
                <h6>Our Story</h6>
                <div className="row">
                  <div className="col-lg-12 col-md-8">
                    <h2>
                      We've been around for {" "}
                      <span>
                        more than 10 years!
                      </span>
                    </h2>
                  </div>
                </div>
              </div>

              <p className="text-meta">
              Expozy is a API-driven microservices solution that gives businesses the ultimate flexibility to take an experience-first approach to commerce, with the fastest and most powerful eCommerce APIs on the market. 
{" "}
              </p>
              <p className="text-meta">
              With Expozy, retailers can develop rich, vibrant brand experiences that engage and convert consumers on any channel or platform. Developers can bring them to life quickly and easily without the constraints of legacy technology.
              </p>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/*  /.fancy-text-block-seventeen */}

      {/* =============================================
				Fancy Text block Eighteen
			============================================== */}
      <div className="fancy-text-block-eighteen mt-70 md-mt-80">
       


<div className=" pt-130 pb-80 md-pt-100 md-pb-100">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-5 ml-auto">
              <img src={Expozyverse} />
              {/*  /.title-style-one */}
            </div>

            <div className="col-lg-7 ml-auto">
              <div className="title-style-one mb-50 md-mb-30">
                <h2>Join the Expozy-verse</h2>
                <p className="font-rubik">
                  Over a decade the Expozy team has been building the perfect eCommerce solution. Having started as a simple eCommerce CMS, Expozy has turned into a vibrant eco-system, hosting more than a hundred eCommerce modules and tools.
                </p>
              </div>
              {/*  /.title-style-one */}
            </div>
          </div>

        
        </div>
      </div>


       
        {/*  /.counter-info */}
      </div>
      {/* /.fancy-text-block-eighteen */}

      {/* 	=============================================
				Team Section Three
			============================================== */}
      <div className="team-section-three">
        <div className="container">
          <div className="d-sm-flex align-items-center justify-content-between mb-50">
            <div className="title-style-four">
              <h2>
                <span>
                  Our Members
                  <img src="images/shape/line-shape-2.svg" alt="shape" />
                </span>
              </h2>
            </div>
          </div>
          {/* End .d-sm-flex */}
        </div>
        {/* End .container */}
        <div className="clearfix">
          <div className="teamSliderOne arrow-top-right">
            <TeamSlider />
          </div>
          {/* /.teamSliderOne */}
        </div>
      </div>
      {/* /.team-section-three */}


               {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                Ready to take your business to the next level?
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/pricing" className="theme-btn-eleven">
              {" "}
              Contact us
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

    

     {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default ExpozyAbout;
