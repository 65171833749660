import React from "react";
import { Link } from "react-router-dom";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";
import Screen35 from "../../../src/assets/images/assets/expozy_no_code.png";
import Icon1 from "../../../src/assets/images/icon/157.svg";
import Icon2 from "../../../src/assets/images/icon/158.svg";
import Icon3 from "../../../src/assets/images/icon/159.svg";
import Screen36 from "../../../src/assets/images/assets/screen_36.png";
import DragDrop from "../../../src/assets/images/assets/drag_drop.jpg";
import Screen37 from "../../../src/assets/images/content/hero_image2.png";

const featureList = [
  {
    icon: Icon1,
    title: "Drag & Drop Builder",
    description: ` Create beautiful storefronts at breakneck speed without
    compromising on your creativity`,
  },
  {
    icon: Icon2,
    title: "Modern Dashboard",
    description: `Good user experience isn't just for your customers. Our dashboard makes work more enjoyable: simple for new users, and customizable for power users.`,
  },
  {
    icon: Icon3,
    title: "Product Modeling",
    description: `Manage your product variations including size, color, add-ons or personalization with the flip of a switch. No more plugins or complex code, just your product options, the way you want them.`,
  },
  {
    icon: Icon3,
    title: "Custom Checkout",
    description: `Checkout is one of the most important parts of the customer experience. Thanks to our powerful APIs, you can create any shopping experience you can imagine.`,
  },
  
];

const FancyTextBlock29 = () => {
  return (
    <div className="container">
      <div className="block-style-thirty bottom-border pb-80 mb-170 md-pb-40 md-mb-90">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="text-block md-pb-60">
              <div className="feature">VITAL FEATURES</div>
              <div className="title-style-nine mb-60 md-mb-40">
                <h2>Features, that matter to you</h2>
              </div>

              <ul className="feature-list-one">
                {featureList.map((list, i) => (
                  <li key={i}>
                    <img
                      src={`${list.icon}`}
                      alt="icon"
                      className="icon"
                    />
                    <strong>{list.title}</strong>
                    <span>{list.description}</span>
                  </li>
                ))}
              </ul>
              {/* End .feature-list-one */}

              <Link to="/platform" className="theme-btn-thirteen mt-20">
                Learn More
              </Link>
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6">
            <img src={Screen35} alt="screen" />

          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

      <div className="block-style-thirty">
        <div className="row">
          <div className="col-xl-5 col-lg-6 order-lg-last">
            <div className="text-block md-pb-60">
              <div className="feature">DRAG & DROP BUILDER</div>
              <div className="title-style-nine mb-45 md-mb-30">
                <h2>Expozy gives you the ability to build your pages just as you imagine them!</h2>
              </div>
              <p>
              A page can have multiple sections. Start with a simple section or choose from the templates library. Templates are clean & minimalistic-designed based on purposes so that you can choose easily and do the editing effortlessly. 
              </p>
              <ul className="feature-list-two">
                <li>
                Browse a reasonable set of typography styles and apply it to a specific section or the entire page with a single click.                </li>
                <li>
                No complex style settings, just simple dialogs that you can play with to get the result or trigger more ideas.                 </li>
                <li>Create multi-section content with slider, video, animated background and many more. .
</li>
              </ul>
              <Link to="/platform" className="theme-btn-thirteen mt-30">
                Learn More
              </Link>
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6 order-lg-first">
            <img src={Screen36} alt="screen" />
            <img src={DragDrop} alt="screen" />

          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

     

      <div className="block-style-thirty mt-150 md-mt-90">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="text-block md-pb-60">
              <div className="feature">SEO FRIENDLY</div>
              <div className="title-style-nine mb-45">
                <h2>Expozy is created with SEO in mind</h2>
              </div>
              <p className="pb-30">
                Expozy's structure is compatible with all latest SEO requirements.
                Additional SEO settings can be found inside the dashboard.
              </p>
              <Link to="/platform" className="theme-btn-thirteen">
                Learn More
              </Link>
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6">
            <img src={Screen37} alt="screen" />
          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

      <div className="mt-30">
        <FancyFeatureThirtyOne />
      </div>
    </div>
  );
};

export default FancyTextBlock29;
