import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Recaptcha from 'react-google-invisible-recaptcha';


const ContactForm = () => {
  // for validation


  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); 

  const refCaptcha = useRef(null)
 

  const handleForm = (e) => {
        // display form data on success
        e.preventDefault();
        console.log(fname);
        console.log(lname);
        console.log(email);
        console.log(message);
        e.target.reset();

        if(fname.length <= 0) {
          toast.error("Please input your first name.")
        }

        if(lname.length <= 0) {
          toast.error("Please input your last name.")
        }

        if(email.length <= 0) {
          toast.error("Please input your email.")
        }

        if(message.length <= 0) {
          toast.error("Please input your message.")
        }

        else {
           // POST request using fetch inside useEffect React hook
     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic dVXQqZFufU1XG7VVOk',
 
      },
      body: JSON.stringify({ "fname": fname, "lname": lname, "email": email, "subject": "Contact form", "message": message  })
  };
  fetch('https://core.expozy.com/api/admin_contacts', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          toast.success("Thank you for contacting us! We'll get back to you shortly.");
        }
        else {
          toast.error('There was an error.');
        }
      }) 
        }

        // dovurshi validaciqta
  }

  return (
    <form id="contact-form" onSubmit={handleForm}>
      <div className="messages"></div>
      <div className="row controls">
        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              onChange={(event) => {
                setFName(event.target.value)
              }}
            />
            
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              onChange={(event) => {
                setLName(event.target.value)
              }}
            />
     
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              onChange={(event)=>{
                setEmail(event.target.value)
              }}
            />
            
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <div className="input-group-meta form-group lg mb-40">
            <label>Message</label>
            <textarea
              placeholder="Your message goes here.."
              name="sendMessage"
              type="text"
              onChange={(event)=>{
                setMessage(event.target.value)
              }}
            ></textarea>
            
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <button type="submit" className="theme-btn-two">
            Send Message
          </button>
        </div>
        {/* End .col */}
      </div>
      <ToastContainer 
      position="bottom-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
<Recaptcha
  ref={refCaptcha}
  sitekey="6LeAmZwbAAAAAOYM5lhUC-Dvat2wVw5L4arlJERk"
  onResolved={ () => console.log( 'Human detected.' ) } />
    </form>
  );
};

export default ContactForm;
