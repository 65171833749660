import React from "react";

const FeatureContent = [
  {
    icon: "23",
    meta: "Generous Commision",
    subTitle: `You can get up to 50% profit on each sale you make, and 100% on all other services you provide the Expozy clients.`,
    dataDelay: "0",
  },
  {
    icon: "25",
    meta: "Start Bonus",
    subTitle: `You get free access to the Expozy plugin & template marketplace as a starting bonus. We're happy to have you!`,
    dataDelay: "100",
  },
  {
    icon: "62",
    meta: "Free Leads",
    subTitle: `We will grant you access to our list of current and potential Expozy customers, who you can start servicing any time!`,
    dataDelay: "300",
  },
  {
    icon: "61",
    meta: "Free Copy",
    subTitle: `You get a subscription for Expozy's Premium Plan, which gives you full access to the Expozy suite. Use your newly acquired power wisely!`,
    dataDelay: "300",
  },
  {
    icon: "60",
    meta: "Marketing Support",
    subTitle: `The Expozy team will provide you with all marketing materials - presentations, charts, certificats, logos, etc. `,
    dataDelay: "300",
  },
];

const FeatureThree = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-three">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.meta}</h4>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureThree;
