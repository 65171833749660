import React from "react";
import Icon163 from "../../../src/assets/images/icon/163.svg";
import Icon164 from "../../../src/assets/images/icon/164.svg";
import Icon165 from "../../../src/assets/images/icon/165.svg";


const featuresContent = [
  {
    icon: Icon163,
    title: "Easy to Customize",
    desc: `All Expozy templates follow the latest web standards and best practices to provide performance, security and conversion.`,
    delayAnimation: "",
  },
  {
    icon: Icon164,
    title: "100+ Templates",
    desc: `Start with a simple section or choose from the templates library. Templates are clean & minimalistic-designed based on purposes so that you can choose easily and do the editing effortlessly.`,
    delayAnimation: "100",
  },
  {
    icon: Icon165,
    title: "Clean & Professional",
    desc: ` Expozy's graphic and web designers have crafted bespoke templates which you can freely use or improve upon`,
    delayAnimation: "200",
  },
];

const FancyFeatureThirtyOne = () => {
  return (
    <div className="row justify-content-between">
      {featuresContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.delayAnimation}
          key={i}
        >
          <div className="block-style-thirtyOne mt-40">
            <div className="icon d-flex align-items-end">
              <img src={`${item.icon}`} alt="icon" />
            </div>
            <h4>{item.title}</h4>
            <p>{item.desc}</p>
          </div>
          {/* <!-- /.block-style-thirtyOne --> */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyOne;
