import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";
import ExpozyLogo from "../../../src/assets/images/media/logo-expo-red.png";

const FooterSeven = () => {
  return (
    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <a href="/">
            <img src={ExpozyLogo} alt="logo" className="expozy-logo" />
          </a>
        </div>
      </div>
      {/* End .col */}

      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="title">Links</h5>
        <ul className="footer-list">
          <li>
            <Link to="/platform">How it works</Link>
          </li>
          <li>
            <Link to="/technology">Technology</Link>
          </li>
          <li>
            <Link to="/enterprise">Enterprise</Link>
          </li>
          <li>
            <Link to="/integrations">Integrations</Link>
          </li>
          <li>
            <Link to="/roadmap">Roadmap</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </div>

      <div
        className="col-lg-3 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="title">Resources</h5>
        <ul className="footer-list">
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy#opt4">Cookie Policy</Link>
          </li>
          <li>
            <Link to="/privacy-policy#opt6">GDPR</Link>
          </li>
          <li>
            <a href="https://wiki.expozy.com" target="_blank">Wiki</a>
          </li>
          <li>
            <a href="https://blog.expozy.com" target="_blank">Blog</a>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/contact-support">Contact Support</Link>
          </li>
        </ul>
      </div>
      <div
        className="col-xl-4 col-lg-5 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Newsletter</h5>
          <p>
            Join over <span>68,000</span> people getting our emails
          </p>

          <FormFooterSignup />

          <div className="info">
            We only send interesting and relevant emails.
          </div>
        </div>
        {/* /.newsletter */}
      </div>
    </div>
  );
};

export default FooterSeven;
