import React from "react";
import useDocumentTitle from "../../components/useDocumentTitle";
import FancyFeatureTen from "../../components/features/FancyFeatureTen";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
import FancyTextBlock21 from "../../components/fancy-text-block/FancyTextBlock21";
import CounterTwo from "../../components/counter/CounterTwo";
import FancyFeatureEleven from "../../components/features/FancyFeatureEleven";
import Pricing from "../../components/pricing/pricing-four/Pricing";
import TestimonialFour from "../../components/testimonial/TestimonialFour";
import FooterFive from "../../components/footer/FooterFive";
import FeatureThree from "../../components/features/FeatureThree";
import FeatureFive from "../../components/features/FeatureFive";


const ProductLanding = () => {
  useDocumentTitle(" Product Landing || Deski-Saas & Software React Template");
  return (
    <div className="main-page-wrapper font-gordita">
      <HeaderLanding />
      {/* End HeaderLanding */}

      <HeroBannerFive />
      {/* End HeaderBannerFive */}

      {/* 	=============================================
				Fancy Feature Ten
			==============================================  */}
      <div className="fancy-feature-ten pt-100 md-pt-70" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six">
                  <h2>
                    The <span>Product</span> we have created.
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-5 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-30 pb-30">
                Deliver peak performance, exceptional shopping experiences and reach optimal sales conversions across all touchpoints with our fully flexible, enterprise-class commerce solution.
                </p>
              </div>
              {/* End .col */}
            </div>
            <FancyFeatureTen />
            {/* End Fancy Feature Ten */}
          </div>
          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-ten */}

      {/*   =============================================
				Fancy Text block Twenty One
			==============================================  */}
      <div className="fancy-text-block-twentyOne pt-170 md-pt-100" id="about">
        <div className="container">
          <FancyTextBlock21 />
        </div>
      </div>
      {/* /.fancy-text-block-twentyOne */}

    

      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-130 md-pt-80" id="product">
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-9 m-auto">
                <div className="title-style-six text-center">
                  <h6>Why Choose Us?</h6>
                  <h2>
                  Why leading brands choose <span>Expozy</span>
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
          {/* End .container */}
           {/* 	=============================================
				Fancy Text block Four
			==============================================  */}
      <div className="fancy-text-block-four pt-130 pb-120 md-pt-100 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>For all kinds of Projects</h2>
                <p className="font-rubik">
                  Duis aute irure dolor in reprehenderit in voluptate velit io
                  cillum dolore eu fugiat nulla pariatur non labrum.
                </p>
              </div>
              {/* /.title-style-one */}
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureThree />
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-four */}

      {/* =============================================
				Fancy Text block Five
			==============================================  */}
      <div className="fancy-text-block-five pt-130 pb-160 md-pt-100 md-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 ml-auto">
              <div className="title-style-one mb-50 md-mb-30">
                <h2>For all kinds of Business Sizes</h2>
                <p className="font-rubik">
                  Excepteur sint occaecat cupidat non proident, sunt in culpa
                  qui officia deserunt mollit anim est laborum.
                </p>
              </div>
              {/*  /.title-style-one */}
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureFive />
            </div>
          </div>
        </div>
      </div>
      {/* /.block-style-five */}
        </div>
        {/* /.inner-container */}
        <img
          src="images/shape/139.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/140.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-feature-eleven */}

      {/*  =====================================================
				Pricing Section Four
			===================================================== */}
      <div className="pricing-section-four pt-200 md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Solo, Agency or Team? We’ve got you <span>covered.</span>
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>
        {/* End .container */}

        <Pricing />
      </div>
      {/*  /.pricing-section-four */}

      {/*  =====================================================
				Client Feedback Slider Four
			===================================================== */}
      <div
        className="client-feedback-slider-four mt-200 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-9 col-md-8 m-auto">
                <div className="title-style-six text-center">
                  <h2>
                    <span>13,000+</span> Clients love Our product
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
          <div className="clientSliderFour slick-nav-none">
            <TestimonialFour />
          </div>
          <img
            src="images/shape/141.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/142.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-four */}

      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2>
                  love our product? <br />
                  <span>Save $20</span> by grab it today.
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            Try it risk free — we don’t charge cancellation fees.
          </p>
          <a
            href="https://themeforest.net/item/deski-saas-software-react-template/33799794"
            className="theme-btn-seven"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            Purchase Now
          </a>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-six */}

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href=""
            target="_blank"
            rel="noreferrer"
          >
            Expozy
          </a>{" "}
          inc.
        </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default ProductLanding;
