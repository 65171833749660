import React from "react";
import Icon1 from "../../../src/assets/images/icon/166.svg";
import Icon2 from "../../../src/assets/images/icon/167.svg";
import Icon3 from "../../../src/assets/images/icon/168.svg";
import Icon4 from "../../../src/assets/images/icon/169.svg";


const featuresContent = [
  {
    bgColor: "#F7EDFF",
    icon: Icon1,
    title: "Chat & Messaging",
    descriptions:
      "Expozy features a real-time custom chatting module, which can be used for communicating with your clients, or between your staff",
    link: "/integrations",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: Icon2,
    title: "eCommerce Module",
    descriptions:
      "The Expozy eCommerce module is the first of its kind - users can choose what type of store and products they'd like to sell",
    link: "/integrations",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: Icon3,
    title: "Payment Gateway",
    descriptions:
      "Expozy offers seemless integration with more than 100+ payment gateways",
    link: "/integrations",
    dealyAnimation: "",
  },
  {
    bgColor: "#FFEBDB",
    icon: Icon4,
    title: "Third Party Integration",
    descriptions:
      "Becase of its API-driven approach, Expozy integrates easily with other 3rd party appss",
    link: "/integrations",
    dealyAnimation: "100",
  },
];

const FancyFeatureThirtyTwo = () => {
  return (
    <div className="row">
      {featuresContent.map((item, i) => (
        <div
          className="col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.dealyAnimation}
          key={i}
        >
          <div className="block-style-thirtyTwo d-flex">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: item.bgColor }}
            >
              <img src={`${item.icon}`} alt="icon" />
            </div>
            <div className="text">
              <h4>{item.title}</h4>
              <p>{item.descriptions}</p>
              <a href={item.link} className="theme-btn-ten">
                Learn more
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyTwo;
