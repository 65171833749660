import React from "react";
import Shape3 from "../../../src/assets/images/shape/232.svg";
import Shape4 from "../../../src/assets/images/shape/233.svg";
import Shape5 from "../../../src/assets/images/shape/234.svg";
import Shape6 from "../../../src/assets/images/shape/235.svg";
import Shape7 from "../../../src/assets/images/shape/236.svg";
import { Link } from "react-router-dom";


const CallToActionSeven = () => {
  return (
    <div className="fancy-short-banner-fourteen mt-150 md-mt-80">
      <div className="container">
        <div className="row">
          <div className="col-12" data-aos="fade-up" data-aos-duration="1200">
            <div className="title-style-thirteen">
              <h2>
                200+ Customers <br />
                use Expozy. Become one of them!
              </h2>
            </div>
            <p>Try it for free — we don’t charge cancellation fees.</p>
            <Link to="/pricing" className="theme-btn-twelve">
              Let’s Get Started
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- /.container --> */}
      <img
          src={Shape5}
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src={Shape6}
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src={Shape3}
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src={Shape4}
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src={Shape5}
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src={Shape7}
          alt="shape"
          className="shapes shape-eight"
        />
    </div>
  );
};

export default CallToActionSeven;
