import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const PasswordRecovery = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(email);

     // POST request using fetch inside useEffect React hook
     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic dVXQqZFufU1XG7VVOk',
 
      },
      body: JSON.stringify({ "email": email })
  };
  fetch('https://core.expozy.com/api/recovery', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          toast.success('Recovery email sent.');
          setTimeout(()=>{
            props.close();
          }, 3000);
        }
        else {
          toast.error(response.response);
        }
      })  
     
  };

 
  return (
    <form className="user-data-form bg-white" onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-md-11 mb-50"  >
        <h3>Recover Password</h3> 

      </div>

      <div className="col-md-1">
      <a className="close-modal-button" onClick={props.close}>
        <i className="fa fa-times-circle-o" style={{fontSize: "2rem", color:"#000"}}>
        </i>
        </a>
      </div>
     
      <div className="col-12">
        <div className="input-group-meta mb-50">
          <label>Email</label>
          <input
            placeholder="Enter Your Email"
            name="email"
            type="email"
            onChange={(event)=> {
              setEmail(event.target.value) 
            }}
          />
        </div>
      </div>
     
      
      <div className="col-12 text-center">
        <button type="submit" className="theme-btn-thirteen mt-30 mb-50 f-none-m-auto">
          Recover Password
        </button>
      </div>

      
      {/* End .col */}
    </div>
    <ToastContainer 
      position="top-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
  </form>
  
  );
};

export default PasswordRecovery;
