import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Customization",
    desc: ` When using a headless solution, you’re not tied to monolithic software that prescribes how a front-end should be structured. This freedom means you don’t have to stick to a specific templating system or train your employees to follow exact rules laid out by a software vendor. Instead, you have full control of what happens on the front-end, and can follow your UX design principles to shape your brand’s identity without having to adhere to a templated layout that makes your sites and apps look and feel like everybody else’s.`,
    expand: "a",
  },
  {
    title: "Freedom To Experiment",
    desc: `In a headless environment, you can do user experience (UX) experiments without the risk of jeopardizing the whole ecosystem. You can A/B test specific parts of your website, or try to build an Alexa skill, without affecting the back-end operations if you run into errors. In contrast, a traditional commerce architecture would force you to modify front-end and back-end code simultaneously – sometimes requiring a shutdown of the entire application for maintenance.`,
    expand: "b",
  },
  {
    title: "Speed & Agility",
    desc: ` IYou can implement new UX changes faster since you don’t have to redeploy a back-end system when working in a decoupled environment. Development becomes much more efficient when teams can work in parallel and UX changes can be made without having to test all the core back-end logic`,
    expand: "d",
  },
  {
    title: "Scaling",
    desc: `In a decoupled environment, the front-end and back-end can be scaled independently so that even if the front-end receives a lot of traffic, this doesn’t affect the back-end.`,
    expand: "e",
  },
  {
    title: "Easily Add New Touchpoints",
    desc: `In a headless scenario, multiple front-ends can connect to one API and underlying system. In other words, if you want to add social channels, kiosks, mobile apps, or in-car marketplace shopping, you can do so easily and quickly. Now you won’t have to build a business case for a new back-end every time you want to add a new front-end.`,
    expand: "f",
  },
  
];

const Faq = () => {
  return (
    <div className="accordion-style-two pr-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
