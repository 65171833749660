import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import ExpozyScreen from "../../../assets/images/assets/screen_35.png";
import ExpozyScreen2 from "../../../assets/images/assets/screen_04.png";
import HeroImage from "../../../assets/images/content/hero_image2.png";
import PortfolioThree from "../../../components/portfolio/PortfolioThree";
import CopyRightThree from "../../../components/footer/CopyRightThree";

const ExpozyIntegrations = () => {
  useDocumentTitle(
    "Integrations | Expozy integrates with hundreds of external tools"
  );

  return (
    <div className="main-page-wrapper">
    <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>
      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">Expozy integrates with hundreds of other apps and tools</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
                Expozy's API-based approach makes it easy to integrate hunderds of external apps or tools for better business development.
</p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* <!-- /.fancy-hero-one --> */}

      {/* =============================================
            Video Box Two
        ==============================================  */}
    <div className="hero-banner-five">



      {/* =============================================
            Fancy Feature Six
        ============================================== */}
      <div className="fancy-feature-six ">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
         
        {/*  =============================================
                    Fancy Portfolio Two
                ==============================================  */}
            <div className="fancy-portfolio-three lg-container">
                <div className="container">
                <div className="top-border bottom-border pb-130 md-pb-90">
                    <PortfolioThree />
                    
                </div>
                </div>
            </div>
            {/* /.fancy-portfolio-two */}

        </div>
      </div>
      {/* /.fancy-feature-six */}

  

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Ready to take your business to the next level?
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/pricing" className="theme-btn-eleven">
              {" "}
              Get Started
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

       {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
    </div>
  );
};


export default ExpozyIntegrations;
