import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useModal } from 'react-hooks-use-modal';
import QuickRegister from "../../../views/inner-pages/features/miscellaneous/QuickRegister";
import axios from 'axios';

const PricingContent = [
  {
    pcakName: "Community",
    bgColor: "#ffecec",
    price: "$0",
    buttonText: "Free trial",
    packDetails: "Everything you need to launch and grow a new online store.",
    packageID: 2,
    features: [
     ""
    ],
    animationDelay: "",
    activeItem: "",
  },
  {
    pcakName: "Premium",
    bgColor: "#E3F8EF",
    price: "$79",
    buttonText: "Buy now",
    packDetails: "Direct support and time-saving features for larger teams.",
    packageID: 3,
    features: [
      ""
    ],
    animationDelay: "100",
    activeItem: "active",
  },
  {
    pcakName: "Advanced",
    bgColor: "#fbf3e5",
    price: "$299",
    buttonText: "Buy now",
    packDetails: "Unlimited scalability, greater control, and dedicated resources.s",
    packageID: 4,
    features: [
      ""
    ],
    animationDelay: "200",
    activeItem: "",
  },
];


const PricingMonthly = () => {

  const [packageName, setPackageName] = useState("");
  const [packageID, setPackageID] = useState("");

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIP(res.data.IPv4)
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    console.log('use effect');
    getData()

  }, [])



  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true
  });



  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeItem}`}>
            <div className="pack-name" style={{ background: val.bgColor }}>
              {val.pcakName}
            </div>
            <div className="price">{val.price}</div>
            <div className="pack-details">{val.packDetails}</div>
           {/*
           <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            */}

            <a onClick={(event)=>{
              open();
              setPackageName(event.target.attributes.getNamedItem('data-package-name').value);
              setPackageID(event.target.attributes.getNamedItem('data-package-id').value);
            }} data-package-name={val.pcakName} data-package-id={val.packageID} className="trial-button">
              {val.buttonText}
            </a>
            <div className="trial-text">No card required, cancel any time</div>
            
          </div>
          {/* /.pr-table-wrapper */}

          <Modal className="modal-register">
            <div className="row">
              <div className="col-md-6 f-none-m-auto">
              <QuickRegister packageName={packageName} close={close} packageID={packageID} ip={ip} />  
              </div>
            </div>
            
          </Modal>

        </div>
      ))}

        
         
        
  
    </div>
  );
};

export default PricingMonthly;
