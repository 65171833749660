import React from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import ExpozyScreen from "../../../assets/images/assets/screen_35.png";
import ExpozyScreen2 from "../../../assets/images/assets/screen_04.png";
import PartnersFaq from "../../../components/faq/PartnersFaq";
import { Link } from "react-router-dom";
import PlatformImage from "../../../assets/images/content/platform_image.png";
import PlatformImage2 from "../../../assets/images/content/platform_image_2.png";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import Partnership from "../../../assets/images/content/partnership.png";
import FeatureThree from "../../../components/features/FeatureThree";
import { useModal } from 'react-hooks-use-modal';
import PartnerForm from "../../../components/contact/form/PartnerForm";


const ExpozyBecomePartner = () => {
  useDocumentTitle("Expozy | Headless, API-driven, microservices eCommerce platform");

  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true
  });


  return (
    <div className="main-page-wrapper font-gordita">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* 	=============================================
            Fancy Hero One
        ============================================== */}
       <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">Become a<br/> Partner</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
                Suitable for web developers, business consultants and many more. Team up with Expozy, resell,
                customize, implement and get up to 50% profit from each sale.
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

        {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Is it worth it?</h6>
                  <h2>
                    <span>
                      What are the benefits {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    of becoming a partner?
                  </h2>
                </div>
                <p className="sub-text">
                    Expozy offers a transparent partnership, with no hidden fees, no unexpected taxes and
                    being a part of an ever-expanding SaaS ecosystem.
                </p>
                
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}

            <div
              className="col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={Partnership} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              
            </div>
            {/* End col-lg-5 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

        <div className="fancy-text-block-four pt-130 pb-120 md-pt-100 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>Some of the benefits</h2>
                <p className="font-rubik">
                    These are only a handfull of the benefits the Expozy partnership brings to the table.
                    Send us your application, we're more than happy to discuss the terms with you.
                </p>
              </div>
              {/* /.title-style-one */}
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureThree />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

              {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>How do I start?</h6>
                  <h2>
                    <span>
                      Register as {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    a partner
                  </h2>
                </div>
                <p className="sub-text">
                    The registration process is really simple - all that is needed
                    to be done is to fill the application form at the bottom of the page
                    and we'll take it from there. Here are all the steps in order to begin:
                </p>
                
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}

            <div
              className="col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
              <ol style={{listStyle: "auto"}}>
                <li>
                  <strong>Complete the training.</strong> Complete the free Expozy course to get up to speed
                  and fit to serve clients. 
                </li>
                <li>
                  <strong>Promote Expozy.</strong> Get access to the Expozy marketing materials,
                  let everyone know that you're using Expozy as part of your stack.
                </li>
                <li>
                  <strong>Earn Profit.</strong> Resell subscriptions or license keys, provide additional services to your Bitrix24 clients! Achieve sales target to get to higher partner status to earn more and unlock even more benefits.

                </li>
              </ol>
              </div>
              {/* <!-- /.img-gallery --> */}
              
            </div>
            {/* End col-lg-5 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}


      {/* =============================================
            Fancy Feature Six
        ============================================== */}
      <div className="fancy-feature-six mt-140 md-mt-70">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <div className="container">
            <div className="title-style-two text-center mb-85 md-mb-40">
              <h2>
                <span>
                  Got any questions?
                  <img src="images/shape/line-shape-2.svg" alt="shape" />
                  
                </span>
              </h2>
              <div className="sub-text mt-15">
                Here are the most frequent questions people ask us.
              </div>
            </div>
            {/* End .title */}
            <PartnersFaq />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-six */}

  

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Ready to partner up? Send us your application!
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <a onClick={open} href="#" className="theme-btn-eleven">
              {" "}
              Apply for partnership
            </a>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

       {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
      <Modal className="modal-register">
            <div className="row">
              <div className="col-md-6 f-none-m-auto">
              <PartnerForm close={close} />  
              </div>
            </div>
            
          </Modal>
    </div>
  );
};

export default ExpozyBecomePartner;
