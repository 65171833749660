import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FancyFeatureIntegrations from "../../components/features/FancyFeatureIntegrations";
import Icon1 from "../../../src/assets/images/icon/166.svg";
import Icon2 from "../../../src/assets/images/icon/167.svg";
import Icon3 from "../../../src/assets/images/icon/168.svg";
import Icon4 from "../../../src/assets/images/icon/169.svg";
import FbMessenger from "../../../src/assets/images/icon/fb_messenger.svg";
import Zendesk from "../../../src/assets/images/icon/zendesk.png";
import GoogleAnalytics from "../../../src/assets/images/icon/google_analytics.png";
import MailChimp from "../../../src/assets/images/icon/mailchimp.png";
import Hubspot from "../../../src/assets/images/icon/hubspot.png";
import FacebookComments from "../../../src/assets/images/icon/icon-facebook-comments.png";
import GoogleTags from "../../../src/assets/images/icon/google-tags.png";
import FacebookLogin from "../../../src/assets/images/icon/facebook-login.png";
import GoogleLogin from "../../../src/assets/images/icon/icon-google-login.png";
import Speedy from "../../../src/assets/images/icon/speedy.png";
import Econt from "../../../src/assets/images/icon/econt.png";
import ExpozyERP from "../../../src/assets/images/icon/logo-expo-red.png";
import VersusERP from "../../../src/assets/images/icon/versus-erp.png";
import Moneto from "../../../src/assets/images/content/moneto_online_bank.png";
import CryptoPayments from "../../../src/assets/images/content/crypto_payments.svg";
import WarrantyBox from "../../../src/assets/images/content/warrantybox.svg";
import YandexMetrica from "../../../src/assets/images/icon/yandex-metrica.png";
import Dhl from "../../../src/assets/images/icon/dhl.png";
import RoyalMail from "../../../src/assets/images/icon/royalmail.png";
import MyPOS from "../../../src/assets/images/icon/myPOS.png";
import Stripe from "../../../src/assets/images/icon/stripe.png";
import Upcloud from "../../../src/assets/images/icon/upcloud.png";
import a1 from "../../../src/assets/images/icon/a1.png";
import Primbg from "../../../src/assets/images/icon/primbg.png";


const TabListContent = ["Chat", "Marketing", "Upgrades", "Shipping", "ERP", "Payment", "Warranty", "Cloud"];

const Chat = [
  {
    bgColor: "#F7EDFF",
    icon: FbMessenger,
    title: "Messenger Chat",
    descriptions:
      "Communicate with your customers with a direct chat connection between your e-store and the Messenger app on your Facebook page.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: Zendesk,
    title: "Zendesk Chat",
    descriptions:
      "Zendesk is one of the best solutions for live chat with your customers.",
    link: "#",
    dealyAnimation: "100",
  }
];

const Marketing = [
  {
    bgColor: "#F7EDFF",
    icon: GoogleAnalytics,
    title: "Google Analytics",
    descriptions:
      "Google Analytics is a tool for measuring your online business.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#E6FBFF",
    icon: YandexMetrica,
    title: "Yandex Metrica",
    descriptions:
      "Yandex.Metrica is a free web analytics service offered by Yandex that tracks and reports website traffic.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: MailChimp,
    title: "MailChimp",
    descriptions:
      "Use this app to create a working and practical e-mail marketing campaign.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: Hubspot,
    title: "Hubspot",
    descriptions:
      "HubSpot is a developer and marketer of software products for inbound marketing and sales.",
    link: "#",
    dealyAnimation: "",
  }
];

const Upgrades = [
  {
    bgColor: "#F7EDFF",
    icon: FacebookComments,
    title: "Facebook Comments",
    descriptions:
      "Facebook Comments allows your customers to comment on store content: products and articles, while they are in their Facebook profile.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: GoogleTags,
    title: "Google Tag Manager",
    descriptions:
      "Google Tag Manager is a container where you can easily add a different programming code to your store.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: FacebookLogin,
    title: "Facebook Login",
    descriptions:
      "With this app, users will be able to quickly and easily sign up and complete their orders through their Facebook profile.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#FFEBDB",
    icon: GoogleLogin,
    title: "Google Login",
    descriptions:
      "This is another convenience for users to become your customers.",
    link: "#",
    dealyAnimation: "100",
  }
];

const Shipping = [
  {
    bgColor: "#F7EDFF",
    icon: Speedy,
    title: "Speedy",
    descriptions:
      "If you use this courier service, the integration will save you a lot of time and resources for order processing.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: Econt,
    title: "Econt",
    descriptions:
      "Integration that saves time and resources of all merchants who use this courier service.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#F7EDFF",
    icon: Dhl,
    title: "DHL",
    descriptions:
      "DHL is a German logistics company providing courier, package delivery and express mail service, which is a division of the German logistics firm Deutsche Post.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#D8FFF1",
    icon: RoyalMail,
    title: "Royal Mail",
    descriptions:
      "Royal Mail Group plc is a British multinational postal service and courier company, originally established in 1516 as a government department.",
    link: "#",
    dealyAnimation: "100",
  }
  
];

const Erp = [
  {
    bgColor: "#F7EDFF",
    icon: ExpozyERP,
    title: "Expozy ERP",
    descriptions:
      "Use the Expozy ERP to run all of your business from a single dashboard.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: ExpozyERP,
    title: "Expozy CRM",
    descriptions:
      "Use the Expozy CRM to manage all your business related resources.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#F7EDFF",
    icon: VersusERP,
    title: "Versus ERP",
    descriptions:
      "This app will be able to import data from your Versus store to your CloudCart store.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#000",
    icon: Primbg,
    title: "Prim BG",
    descriptions:
      "ERP, CRM and BI system for your sales, purchases, logistics management and financial operations, organised in the way successful companies work.",
    link: "#",
    dealyAnimation: "100",
  }
];

const Payment = [
  {
    bgColor: "#F7EDFF",
    icon: Moneto,
    title: "Moneto",
    descriptions:"Moneto Digital is a Wallet and Digital Banking solution which support Fiat, Crypto and Bank Account Management.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: CryptoPayments,
    title: "Coin Payments",
    descriptions:
      "Crypto payments made easy. Take advantage of our global crypto payment gateway made easy and accessible for everyone — whether you're a business owner, crypto user, or even from another planet.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#F7EDFF",
    icon: MyPOS,
    title: "MyPOS",
    descriptions:
      "Everything you need to accept payments and sell in-store, online and everywhere in between.",
    link: "#",
    dealyAnimation: "100",
  },
  {
    bgColor: "#D8FFF1",
    icon: Stripe,
    title: "Stripe",
    descriptions:
      "Online payment processing for internet businesses. Stripe is a suite of payment APIs that powers commerce for online businesses of all sizes.  ",
    link: "#",
    dealyAnimation: "100",
  }
];

const Warranty = [
  {
    bgColor: "#F7EDFF",
    icon: WarrantyBox,
    title: "WarrantyBox",
    descriptions:
      "Use the Expozy ERP to run all of your business from a single dashboard.",
    link: "#",
    dealyAnimation: "",
  }
];

const Cloud = [
  {
    bgColor: "#F7EDFF",
    icon: Upcloud,
    title: "Upcloud",
    descriptions:
      "UpCloud provides enterprise-grade cloud platform for SMBs. Fast and reliable cloud with 100% uptime and 24/7 in-house support.",
    link: "#",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: a1,
    title: "A1 Cloud",
    descriptions:
      "A1 Telekom Austria is the leading fixed and mobile network operator in Austria, with 5.4 million mobile and 2.3 million fixed-line customers.",
    link: "#",
    dealyAnimation: "100",
  }
 
];

const PortfolioThree = () => {
  return (
      <Tabs className="portfolio-container">
        <div className="controls po-control-one text-center mb-90 md-mb-50 mt-90 md-mt-60">
          <TabList className="d-flex flex-wrap justify-content-center">
            {TabListContent.map((tab, i) => (
              <Tab key={i} className="filter-button">
                <button type="button" className="control">
                  {tab}
                </button>
              </Tab>
            ))}
          </TabList>
        </div>
    
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Chat.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Marketing.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Upgrades.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Shipping.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Erp.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Payment.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Warranty.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="justify-content-center gutter-space-one d-flex flex-wrap">
            <div className="container row">
              {Cloud.map((item, i) => (
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.dealyAnimation}
                  key={i}
                >
                  <div className="block-style-thirtyTwo d-flex">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      style={{ background: item.bgColor }}
                    >
                      <img src={`${item.icon}`} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <p>{item.descriptions}</p>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </TabPanel>
      </Tabs>
  );
};

export default PortfolioThree;
