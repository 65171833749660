import React from "react";
import Icon163 from "../../../src/assets/images/content/CompTIA_PenTest_2B.png";
import Icon164 from "../../../src/assets/images/content/Credly_CPENT_Badge_Upload.png";
import Icon165 from "../../../src/assets/images/content/PWK-OSCP-badge.png";


const featuresContent = [
  {
    icon: Icon163,
    delayAnimation: "",
  },
  {
    icon: Icon164,
    delayAnimation: "100",
  },
  {
    icon: Icon165,
    delayAnimation: "200",
  },
];

const PenTestIcons = () => {
  return (
    <div className="row justify-content-between">
      {featuresContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.delayAnimation}
          key={i}
        >
          <div className="block-style-thirtyOne mt-40">
            <div className="d-flex justify-content-center align-items-center">
              <img src={`${item.icon}`} alt="icon" className="pentest-icon"/>
            </div>
          </div>
          {/* <!-- /.block-style-thirtyOne --> */}
        </div>
      ))}
    </div>
  );
};

export default PenTestIcons;
