import React from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import ExpozyScreen from "../../../assets/images/assets/screen_35.png";
import ExpozyScreen2 from "../../../assets/images/assets/screen_04.png";
import Faq from "../../../components/faq/Faq";
import { Link } from "react-router-dom";
import PlatformImage from "../../../assets/images/content/platform_image.png";
import PlatformImage2 from "../../../assets/images/content/platform_image_2.png";
import CopyRightThree from "../../../components/footer/CopyRightThree";

const ServiceV1 = () => {
  useDocumentTitle("Expozy | Headless, API-driven, microservices eCommerce platform");
  return (
    <div className="main-page-wrapper font-gordita">
 <div className="inner-page-white-nav-items">
      <HeaderLandingWebsiteBuilder  />
    </div>      {/* End Header */}

      {/* 	=============================================
            Fancy Hero One
        ============================================== */}
       <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">eCommerce <br/> Without Limits</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
                Expozy is a next generation eCommerce platform, aiming to simplify creating, launching and succeeding  with an online business.
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

        {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Why us?</h6>
                  <h2>
                    <span>
                      MACH {" "}
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    Architecture
                  </h2>
                </div>
                <p className="sub-text">
                Expozy is based on the MACH architecture (Microservices, API-first, Cloud-native, Headless).
                </p>
                <p className="sub-text pb-35">
                Our true cloud-native commerce platform enables businesses to service audiences across all digital touchpoints and provides the building blocks for the new digital commerce age. 
                The API approach helps retailers create brand value by empowering commerce teams to design unique and engaging digital commerce experiences everywhere today and in the future. 

                </p>
                
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}

            <div
              className="col-lg-5 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={PlatformImage} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              <div className="text-wrapper">
               
               
                <p className="sub-text pb-35">
                Our agile, componentized architecture improves profitability by significantly reducing development time and resources required to migrate to modern commerce technology and meet new customer demands.
                </p>
               
              </div>
            </div>
            {/* End col-lg-5 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

          {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
      <div className="fancy-text-block-twelve mt-170 md-mt-100">
        <div className="container">
          <div className="row">

            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="img-gallery">
                <img src={PlatformImage2} alt="" />
                <img
                  src="images/shape/53.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/52.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
              </div>
              {/* <!-- /.img-gallery --> */}
              <div className="text-wrapper">
                <p className="sub-text">In addition, our solution will allow you to deliver high-conversion commerce experiences faster than your competitors.</p>
            </div>
            </div>
            {/* End col-lg-5 */}

            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-three">
                  <h6>Why Microservices?</h6>
                  <h2>
                    <span>
                     Microservices - 
                      <img src="images/shape/line-shape-6.svg" alt="" />
                    </span>
                    the ecommerce architecture of tomorrow
                  </h2>
                </div>
                <p className="sub-text pb-35">
                Microservices are considered the enablers of the eCommerce of tomorrow. Encapsulating each business capability into individual services that interact, our state-of-the-art software technology will provide your organization with unparalleled performance, technical agility, high availability, elastic scalability and seamless upgrades. 
                </p>
            
              </div>
              {/* <!-- /.text-wrapper --> */}
            </div>
            {/* End .col-6 */}
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-text-block-twelve --> */}

      {/* =============================================
            Fancy Feature Six
        ============================================== */}
      <div className="fancy-feature-six mt-140 md-mt-70">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <div className="container">
            <div className="title-style-two text-center mb-85 md-mb-40">
              <h2>
                <span>
                  Countless Benefits
                  <img src="images/shape/line-shape-2.svg" alt="shape" />
                </span>
              </h2>
              <div className="sub-text mt-15">
                Get to know all Expozy features, that are part of the complex
                multi-channel
              </div>
            </div>
            {/* End .title */}
            <Faq />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-six */}

  

     
            {/* =====================================================
            Fancy Short Banner Ten
        ===================================================== */}
      <div className="fancy-short-banner-ten mt-130 md-mt-20">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center pb-60">
                <h2 className="font-gordita">
                  Convinced to work on a new project?
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div
            className="text-center pb-40 md-pb-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <Link to="/pricing" className="theme-btn-eleven">
              {" "}
              Get Started
            </Link>
          </div>
        </div>
        {/* /.container */}
        <img src="images/shape/210.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/211.svg" alt="" className="shapes shape-two" />
      </div>
      {/* /.fancy-short-banner-ten */}

       {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default ServiceV1;
